import React from "react"
import { Button, Form, Input, Select } from "antd"
import { useTranslation } from "react-i18next"
import provinceList from "../../static/province_data.json"

const ShippingAddressForm = ({ initialValues, onFinish, submitButtonText }) => {
  const { t } = useTranslation()

  const _onFinish = values => {
    onFinish(values)
  }

  return (
    <Form
      initialValues={initialValues}
      onFinish={_onFinish}
      layout={"vertical"}
    >
      <Form.Item
        label={t("form:label.firstname")}
        name="firstname"
        rules={[
          {
            required: true,
            message: t("form:error.firstnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.firstname")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.lastname")}
        name="lastname"
        rules={[
          {
            required: true,
            message: t("form:error.lastnameRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.lastname")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.streetAddress")}
        name={["address", "address_line1"]}
        rules={[
          {
            required: true,
            message: t("form:error.streetAddressRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.streetAddress")} size="large" />
      </Form.Item>

      <Form.Item label={t("form:label.extendedAddress")} name="extendedAddress">
        <Input placeholder={t("form:label.extendedAddress")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.locality")}
        name={["address", "city"]}
        rules={[
          {
            required: true,
            message: t("form:error.localityRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.locality")} size="large" />
      </Form.Item>

      <Form.Item
        label={t("form:label.region")}
        name={["address", "region"]}
        rules={[
          {
            required: true,
            message: t("form:error.regionRequired"),
          },
        ]}
      >
        <Select
          placeholder={t("form:label.region")}
          size="large"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {provinceList.map(({ name, code }) => (
            <Select.Option key={code} value={code}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("form:label.postalCode")}
        name={["address", "postal_code"]}
        rules={[
          {
            required: true,
            message: t("form:error.postalCodeRequired"),
          },
        ]}
      >
        <Input placeholder={t("form:label.postalCode")} size="large" />
      </Form.Item>

      <div className="form-submit-button-wrapper">
        <Form.Item>
          <Button size="large" className="red" type="primary" htmlType="submit">
            {submitButtonText}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default ShippingAddressForm

import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import PageRoot from "../../components/PageRoot"
import QueryString from "query-string"
import useCertification from "../../hooks/useCertification"
import { gql, useQuery } from "@apollo/client"
import { Button, Radio, Skeleton, Space } from "antd"
import ErrorScreen from "../../components/ErrorScreen"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { uiHandleError } from "../../utils"
import ShippingSuccess from "../../components/ShippingSuccess"
import ShippingAddressForm from "../../components/forms/ShippingAddressForm"
import { EXAM_NOT_PASSED_YET } from "../../../functions/lib/error-codes"

const BILLING_PROFILES_QUERY = gql`
  query getCertificationShipping($certificationId: uuid!) {
    billing_profiles {
      billing_address
      firstname
      lastname
      phone_number
    }
    certifications_by_pk(id: $certificationId) {
      id
      title
      subtitle
      landscape_success {
        s3_key
      }
    }
    user_certification_states(
      where: { certification_id: { _eq: $certificationId } }
    ) {
      passed
      shipped
      shipping_required
      shipping_address
    }
  }
`

const formatAddress = ({ firstname, lastname, billing_address, address }) => {
  const _address = billing_address || address
  return (
    <p>
      {firstname} {lastname}
      <br />
      {_address.address_line1}
      <br />
      {_address.city}
      <br />
      {_address.postal_code}
    </p>
  )
}

const RequireCertificationShippingPage = ({ location }) => {
  const { t } = useTranslation()
  const params = QueryString.parse(location.search)
  const { id } = params
  const { requireShipping } = useCertification()
  const [selectedAddressIndex, setSelectedAddressIndex] = useState()
  const [success, setSuccess] = useState(false)
  const [shippingAddress, setShippingAddress] = useState(null)

  const { loading, error, data } = useQuery(BILLING_PROFILES_QUERY, {
    variables: { certificationId: id },
  })

  const handleRequireShipping = async address => {
    try {
      setShippingAddress(address)
      await requireShipping({ certificationId: id, shippingAddress: address })
      setSuccess(true)
    } catch (err) {
      uiHandleError({ error: err })
    }
  }

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton active round />
    }

    if (error) {
      return <ErrorScreen error={error} />
    }

    if (success) {
      return (
        <ShippingSuccess
          shippingAddress={shippingAddress}
          certification={data.certifications_by_pk}
        />
      )
    }

    if (!data.user_certification_states[0]?.passed) {
      return (
        <ErrorScreen
          error={new Error(EXAM_NOT_PASSED_YET)}
          learnMoreUrl={`/go?id=${id}`}
        />
      )
    }

    if (data.user_certification_states[0]?.shipping_required) {
      return (
        <ShippingSuccess
          shippingAddress={data.user_certification_states[0]?.shipping_address}
          certification={data.certifications_by_pk}
        />
      )
    }

    if (!data.billing_profiles?.length) {
      return (
        <div>
          <h1
            style={{
              fontSize: 36,
              fontWeight: "bold",
              marginBottom: 50,
              marginTop: 30,
            }}
          >
            {t("label:shipCertificationTo")}:
          </h1>
          <ShippingAddressForm
            onFinish={values => {
              handleRequireShipping(values)
            }}
            submitButtonText={t("button:requireCertificationShipping")}
          />
        </div>
      )
    }

    return (
      <div>
        <h1
          style={{
            fontSize: 36,
            fontWeight: "bold",
            marginBottom: 50,
            marginTop: 30,
          }}
        >
          {t("label:shipCertificationTo")}:
        </h1>
        <Radio.Group
          onChange={evt => {
            setSelectedAddressIndex(evt.target.value)
          }}
          value={selectedAddressIndex}
        >
          <Space direction="vertical">
            {data.billing_profiles.map((profile, index) => (
              <Radio key={index} value={index}>
                {formatAddress(profile)}
              </Radio>
            ))}
            <Radio value={-1}>{t("label:shipToDifferentAddress")}</Radio>
          </Space>
        </Radio.Group>
        <br />
        <br />
        {selectedAddressIndex !== -1 ? (
          <Button
            className="red"
            onClick={() => {
              const address = { ...data.billing_profiles[selectedAddressIndex] }
              address.address = address.billing_address
              delete address.billing_address

              handleRequireShipping(address)
            }}
          >
            {t("button:requireCertificationShipping")}
          </Button>
        ) : (
          <ShippingAddressForm
            onFinish={values => {
              handleRequireShipping(values)
            }}
            submitButtonText={t("button:requireCertificationShipping")}
          />
        )}
      </div>
    )
  }, [loading, error, data, selectedAddressIndex, success])

  return (
    <PageRoot isDark={false} title={t("label:requireCertificationShipping")}>
      <ContainerWithPadding size="small">{content}</ContainerWithPadding>
    </PageRoot>
  )
}

export default RequireCertificationShippingPage

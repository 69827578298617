import React from "react"
import "./ShippingSuccess.less"
import ContainerWithPadding from "./ContainerWithPadding"
import { Button, Card } from "antd"
import { Link } from "gatsby"
import { devLog, getImageUrl, translatePath } from "../utils"
import { useTranslation } from "react-i18next"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const ShippingSuccess = ({ shippingAddress, certification }) => {
  const { t } = useTranslation()

  devLog({ shippingAddress })

  const { landscape_success, title } = certification

  const { firstname, lastname, address } = shippingAddress

  const successCover = getImageUrl(
    landscape_success,
    DEFAULT_LANDSCAPE_IMAGE_SIZE
  )

  return (
    <ContainerWithPadding size="full" align="center">
      <div className="shipping-result-wrapper">
        <h1 className="result-title success">
          {t("label:shippingRequestSuccess")}
        </h1>
        <p className="message success">
          {t("message:certificationIsShippingToSelectedAddress")}
        </p>
        <div className="certification-preview-wrapper">
          <Card
            style={{ width: "100%", marginBottom: 50 }}
            hoverable
            cover={<img alt={title} src={successCover} />}
          />
        </div>
        <p>
          <b>{t("label:shippingAddress")}:</b>
          <br />
          {firstname} {lastname}
          <br />
          {address.address_line1}
          <br />
          {address.city}, {address.region}, {address.postal_code}
        </p>
        <Button className="red">
          <Link to={`${translatePath("/certification/all")}`}>
            {t("button:gotoCertifications")}
          </Link>
        </Button>
      </div>
    </ContainerWithPadding>
  )
}

export default ShippingSuccess
